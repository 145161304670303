import {
  FileUploaderConfig,
  FileUploaderConfigHeader,
  FileUploaderEvent,
} from "../typings/fileuploaderconfig";

export class FileUploaderDefaultConfig implements FileUploaderConfig {
  uploadUrl: string = "http://localhost:3000/upload";
  headers?: FileUploaderConfigHeader;
  events?: FileUploaderEvent | undefined;
}
