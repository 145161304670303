import { Editor } from "@ckeditor/ckeditor5-core";
import { ButtonView, View, submitHandler } from "@ckeditor/ckeditor5-ui";
import { Locale } from "@ckeditor/ckeditor5-utils";
import GoogleDriveIcon from "../../theme/icons/google_drive.png"
import EgeneDriveIcon from "../../theme/icons/egene_drive.png"
import LocalIcon from "../../theme/icons/local.png"


export default class UploadKindView extends View {
  private readonly editor: Editor;
  private readonly googleDriveButton: ButtonView | undefined;
  private readonly egeneDriveButton: ButtonView;
  private readonly PCButton: ButtonView;

  constructor(editor: Editor, locale?: Locale) {
    super(locale ?? editor.locale);
    this.editor = editor;

    this.egeneDriveButton = this._createButton("E-Gene Drive", 'egenedrive', EgeneDriveIcon);
    this.PCButton = this._createButton("PC", 'pc', LocalIcon);

    const invisibleInputFile = new View();
    invisibleInputFile.setTemplate({
      tag: 'input',
      attributes: {
        type: 'file',
        id: 'ck-upload-kind-local-input',
        style: 'display: none',
        multiple: "multiple"
      }
    });
    this.PCButton.children.add(invisibleInputFile);

    let children = [ this.PCButton ]

    if ((window as any).$egene.app.id === 'pms')
    {
      this.egeneDriveButton.delegate('execute').to(this, 'egeneDrive');
      children.push(this.egeneDriveButton);
    }
    this.PCButton.delegate('execute').to(this, 'pc');

    if (this._checkGapi())
    {
      this.googleDriveButton = this._createButton("Google Drive", 'googledrive', GoogleDriveIcon);
      this.googleDriveButton.delegate('execute').to(this, 'googleDrive');
      children.push(this.googleDriveButton);

      const gapi = (window as any).gapi;
      gapi.load('picker');
    }

    this.setTemplate({
      tag: 'div',
      attributes: {
          class: ['ck', 'ck-upload-kind'],
          tabindex: '-1'
      },
      children
    });
  }

  override render() {
    super.render();

    submitHandler({
      view: this
    });
  }

  private _createButton(label: string, className: string, icon?: string): ButtonView {
    const button = new ButtonView();

    button.set({
      label: this.editor.t(label),
      class: className,
      tooltip: true,
      withText: true
    })

    const img = new View();
    img.setTemplate({
      tag: "img",
      attributes: {
        class: ['ck', `ck-upload-kind-${className}-img`],
        src: icon ?? ""
      }
    });

    button.children.add(img);

    return button;
  }

  private _checkGapi(): boolean {
    return typeof (window as any).gapi !== 'undefined'
  }
}
