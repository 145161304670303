import { Editor, Plugin } from "ckeditor5/src/core";

import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'
import { FileUploaderAdapter } from "./fileuploaderadapter";
import { FileUploaderDefaultConfig } from "./fileuploaderdefaultconfig";
import { FileRepository, UploadAdapter } from "@ckeditor/ckeditor5-upload";
import type Element from "@ckeditor/ckeditor5-engine/src/model/element";
import { FileUploaderUI, UploadKindView } from "./ui";
import { v4 as uuidv4 } from "uuid";
import { Range } from "ckeditor5/src/engine";
import { ContextualBalloon, clickOutsideHandler } from "@ckeditor/ckeditor5-ui"
import type { FileUploaderConfig } from "../typings/fileuploaderconfig";
import { checkMIME } from "./utils"
import "jqueryui";
import $ from "jquery"

export default class Fileuploader extends Plugin {
  private readonly FILE_EXTENSION: RegExp = /(?:\.([^.]+))?$/;
  private readonly _uploadFileBoxElements: Map<string, Element>; 
  private _uploadKindView: UploadKindView | undefined;
  private _balloon: ContextualBalloon | undefined;
  private _fileuploadButton: ButtonView | undefined;
  private _config: FileUploaderConfig | undefined;
  private _fileRepo: FileRepository | undefined;
  private _listId: string | undefined;

  public static get pluginName(): "FileUploader" {
    return "FileUploader";
  }

  public static get requires(): any {
    return [FileUploaderUI, ContextualBalloon] as const;
  }

  constructor( editor: Editor ) {
    super( editor );

    this._uploadFileBoxElements = new Map();
  }

  private _closeBalloon(): void {
    this._balloon!.remove( this._uploadKindView! )
  }

  public init(): void {
    const editor = this.editor;
    const t = editor.t;
    const viewDocument = editor.editing.view.document;
    this._config =
      editor.config.get("file_uploader") || new FileUploaderDefaultConfig();
    this._listId = this._config.list_id;
    const gapiConfig = this._config.gapi;
    const accessToken = gapiConfig?.access_token;
    const apiKey = gapiConfig?.api_key;

    this._balloon = this.editor.plugins.get(ContextualBalloon);
    this._uploadKindView = new UploadKindView(editor, editor.locale);

    clickOutsideHandler({
      emitter: this._uploadKindView!,
      activator: () => this._balloon!.visibleView === this._uploadKindView!,
      contextElements: [ this._balloon!.view.element! ],
      callback: () => this._closeBalloon()
    });

    this._fileRepo = editor.plugins.get("FileRepository");
    this._fileRepo.createUploadAdapter = (loader): UploadAdapter => {
      return new FileUploaderAdapter(loader, this._config!);
    };

    this.on<ImageUploadCompleteEvent>( 'uploadComplete', ( evt, { data, box } ) => {
			this.editor.model.change( writer => {
				writer.setAttribute( 'src', data.default, box );
			} );
		}, { priority: 'low' } );

    // Clipboard Input
    editor.editing.view.document.on("clipboardInput", (event, data) => {
      const dataTransfer = data.dataTransfer;
      const files: File[] = dataTransfer.files;

      if (!files.length || files.length == 0) {
        return;
      }

      event.stop();
      files.forEach((file) => {
        this._uploadFile(file, data);  
      });
    });

    // Create button
    editor.ui.componentFactory.add("fileupload", () => {
      let _fileuploadButton = this._fileuploadButton = new ButtonView();
      _fileuploadButton.set({
        label: t("File Upload"),
        class: 'file-uploader-button',
      });

      this.listenTo(_fileuploadButton, 'execute', () => {
        this._showUploadView();
      })

      return _fileuploadButton;
    });
    
    this.listenTo(this._uploadKindView!, 'googleDrive', (data) => {
      const google = (window as any).google;
      const myFiles = new google.picker.DocsView(google.picker.ViewId.DOCS)
        .setSelectFolderEnabled(false)
        .setEnableDrives(false)
        .setIncludeFolders(true)
        .setOwnedByMe(true);

      const sharedFiles = new google.picker.DocsView(google.picker.ViewId.DOCS)
        .setSelectFolderEnabled(false)
        .setIncludeFolders(true)
        .setOwnedByMe(false);

      const picker = new google.picker.PickerBuilder()
            .addView(myFiles)
            .addView(sharedFiles)
            .addView(new google.picker.View(google.picker.ViewId.RECENTLY_PICKED))
            .setOAuthToken(accessToken)
            .setDeveloperKey(apiKey)
            .setCallback((data: any) => {
              switch (data.action) {
                case "picked":
                  const selectedFile = data.docs[0];
                  selectedFile.kind = 'GOOGLE_DRIVE';
                  this._createDriveFileBox(selectedFile.id, selectedFile, "append");
                  break;
                default:
                  break;
              }
            })
            .setLocale(gapiConfig?.local)
            .build();
        picker.setVisible(true);
    });

    const _createDriveFileBox = this._createDriveFileBox.bind(this);
    this.listenTo(this._uploadKindView!, 'egeneDrive', (data) => {
      let dialog_popup = $('.dialog_popup');

      if (dialog_popup.length == 0)
      {
          // Custom Dialog 생성
          dialog_popup = $('<div class="dialog_popup"></div>');
          // append Dialog to body
          $('body').append(dialog_popup);
      }

      (dialog_popup as any).loadPage('/xefc/jsp/ui/drive/egeneDrive.jsp', {
          lst_id: this._listId
      }, function ()
      {
          const modalPosition = {
              of: '.egene-root',
              at: 'center',
              my: 'center'
          };
          // 로딩이 완료되면 dialog 팝업모듈 생성
          const dialog = dialog_popup.dialog({
              title: "E-Gene Drive에서 선택하기",
              autoOpen: true,
              height: 700,
              width: '100%',
              position: modalPosition,
              modal: true,
              buttons: {
                  "삽입": function ()
                  {
                    const gridElement = dialog_popup.find('.egene-list.jqx-grid') as any;
                    const index = gridElement.jqxGrid('getselectedrowindex') as number;

                    if (index < 0) {
                      return false;
                    } else {
                      const selectedRowData = gridElement.jqxGrid('getrowdata', index);
                      selectedRowData.kind = 'EGENE_DRIVE';
                      selectedRowData.sizeBytes = selectedRowData.sizebytes;

                      _createDriveFileBox(selectedRowData.id, selectedRowData, undefined);
                      closeModalDlg();
                    }
                  },
                  "취소": function ()
                  {
                      closeModalDlg();
                  }
              },
              create: function ()
              {
                  $(this).parent().css('maxWidth', '1162px');
              },
              close: function() {
                closeModalDlg();
              }
          });

          //  $(".-titlebar").hide();

          $(".-buttonpane").hide();

          $('.ui-dialog').css('z-index', 19999);

          /**
           * 팝업 닫기 처리
           */
          function closeModalDlg()
          {
              dialog.dialog("close");
              dialog.remove();
          }

          const $egene = (window as any).$egene;
          $egene.dialog.ref = dialog;
          // $egene.dialog.cbFunc = cbFunc;
          $egene.dialog.close = closeModalDlg; // 창 닫기 기능
      });
    
    });

    this.listenTo(this._uploadKindView!, 'pc', (data) => {
      const source = data.source as any;
      const childs = source.element.children;

      childs[1].click();

      const uploadFile = this._uploadFile.bind(this);
      const closeBalloon = this._closeBalloon.bind(this);
      childs[1].onchange = function () {
        if (this.files.length > 0) {
          for (let i = 0; i < this.files.length; i++) {
            const file = this.files[i];
            if (checkMIME(file, "image/"))
            {
              editor.execute("uploadImage", { file: [file] });
              continue;
            }
            uploadFile(file, 'append');
          }
        }
        closeBalloon();
      }
    });

    this.listenTo(viewDocument, 'click', (evt, data) => {
      if (data.domTarget.className === 'ck-file-download' && data.domTarget.tagName === 'IMG')
      {
        const actualBox = data.target.parent.parent.parent;
        window.open(actualBox.getAttribute("src"), "_blank");
      }
    })
  }

  private _showUploadView() {
    this._balloon!.add({
      view: this._uploadKindView!,
      singleViewMode: true,
      position: {
        target: this._fileuploadButton!.element!
      }
    });
  }

  private _uploadFile(file: File, data: any): void {
    const config = this._config!;
    const model = this.editor.model;

    const tempId = this._createFileBox(file, data)!;
    let loader = this._fileRepo!.createLoader(file)!;

    model.enqueueChange({ isUndoable: false }, (writer) => {
      writer.setAttribute("upload-status", "reading", this._uploadFileBoxElements.get(tempId)!);
    });

    loader
      .read()
      .then(() => {
        const promise = loader.upload();
        const box = this._uploadFileBoxElements.get(tempId)!;

        model.enqueueChange({ isUndoable: false }, (writer) => {
          writer.setAttribute("upload-status", "uploading", box);
        });

        return promise;
      })
      .then((data) => {
        model.change((writer) => {
          const box = this._uploadFileBoxElements.get(tempId)!;
          writer.setAttribute("upload-status", "complete", box);

          this.fire<ImageUploadCompleteEvent>('uploadComplete', { data, box });
          this._uploadFileBoxElements.delete( tempId );

          this._fileRepo!.destroyLoader( loader );
        });
      })
      .catch((error) => {
        if (loader.status === "error" || loader.status === "aborted") {
          const $egene = (window as any).$egene;
          $egene.alert(error);
          this._removeFileBox(tempId);
        }
      });
  }

  private _createDriveFileBox(id: string, fileData: any, data: any) {
    let attributes: { [key: string]: any } = {
      "file-id": id,
      "file-title": fileData.name,
      "file-size": fileData.sizeBytes,
      "file-type": this.FILE_EXTENSION.exec(fileData.name)![1],
      "src": fileData.url,
      "upload-kind": fileData.kind
    }

    let modelRange: Range | null;
    if ( data && data.targetRanges ) {
      modelRange = this.editor.editing.mapper.toModelRange( data.targetRanges[ 0 ] );
    }
    else {
      modelRange = this.editor.model.document.selection.getFirstRange();
    }

    const selection = this.editor.model.createSelection( modelRange );

    this.editor.model.change((writer) => {
      let fileBox = writer.createElement("ck-file-box", attributes);

      if (data === 'append')
      {
        this.editor.model.insertObject(fileBox, this.editor.model.document.getRoot(), "end", {
          findOptimalPosition: 'auto',
          setSelection: 'on'
        });
      } else {
        this.editor.model.insertObject(fileBox, selection, null, {
          findOptimalPosition: 'auto',
          setSelection: 'on'
        });
      }

      this._uploadFileBoxElements.set( id, fileBox );
    });
    
    if (attributes["upload-kind"] === 'EGENE_DRIVE')
    {
      this.editor.model.enqueueChange({ isUndoable: false }, (writer) => {
        writer.setAttribute("upload-status", "uploading", this._uploadFileBoxElements.get( id )!);
      });
      this.editor.model.enqueueChange({ isUndoable: false }, (writer) => {
        writer.setAttribute("upload-status", "complete", this._uploadFileBoxElements.get( id )!);
      });
    }
  }

  private _createFileBox(file: File, data: any, id?: string): string | undefined {
    const temp_id = id || uuidv4();
    let extension = this.FILE_EXTENSION.exec(file.name)![1] || "Empty";
    let attributes = {
      "file-id": temp_id,
      "file-title": file.name,
      "file-size": file.size,
      "file-type": extension,
      "upload-kind": "FILE"
    };

    let modelRange: Range | null;
    if ( data && data.targetRanges ) {
      modelRange = this.editor.editing.mapper.toModelRange( data.targetRanges[ 0 ] );
    }
    else {
      modelRange = this.editor.model.document.selection.getFirstRange();
    }
    
    const selection = this.editor.model.createSelection( modelRange );

    this.editor.model.change((writer) => {
      let fileBox = writer.createElement("ck-file-box", attributes);

      if (data === 'append')
      {
        this.editor.model.insertObject(fileBox, this.editor.model.document.getRoot(), "end", {
          findOptimalPosition: 'auto',
          setSelection: 'on'
        });
      } else {
        this.editor.model.insertObject(fileBox, selection, null, {
          findOptimalPosition: 'auto',
          setSelection: 'on'
        });
      }
      this._uploadFileBoxElements.set(temp_id, fileBox);
    });

    return temp_id;
  }

  private _removeFileBox(tempId: string) {
    const elem = this._uploadFileBoxElements.get(tempId);
    this.editor.model.change((writer) => {
      writer.remove(elem!);
      this._uploadFileBoxElements.delete(tempId);
    });
  }
}

type ImageUploadCompleteEvent = {
	name: 'uploadComplete';
	args: [{[key: string]: any}];
};
