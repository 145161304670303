(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("JQueryUI"), require("jQuery"));
	else if(typeof define === 'function' && define.amd)
		define(["JQueryUI", "jQuery"], factory);
	else if(typeof exports === 'object')
		exports["ClassicEditor"] = factory(require("JQueryUI"), require("jQuery"));
	else
		root["ClassicEditor"] = factory(root["JQueryUI"], root["jQuery"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__1840__, __WEBPACK_EXTERNAL_MODULE__1145__) => {
return 